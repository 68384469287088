.qr-wrapper {
    display: flex;
    justify-content: center;
    position: relative;
}

.qr-header {
    text-align: center;
}

.qr-text{
    max-width: 285px;
    margin: 10px auto 0 auto;
    text-align: center;
}

#downloadButton {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
}

#downloadButton:hover {
    background: url("/assets/img/download.png") center no-repeat, #21212163;
    background-size: 150px ;
    /*background-color: rgba(20, 83, 136, 0.1);*/
}
